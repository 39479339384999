/*
 * @Last Modified by: Gil
 * @Last Modified time: 2025-02-25
 */
import React from 'react'

import Container from 'react-bootstrap/Container'
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'

function NavBarTop({message, userEmail}) {
    function cannotViewPrivateContent(email) {    // used to hide the yield curve and the compliance modules from everyone except those listed in `domainsThatCanViewPrivateContent`
        const domainsThatCanViewPrivateContent = ['ficc.ai']
        for (var idx = 0; idx < domainsThatCanViewPrivateContent.length; idx++) {
            var domain = domainsThatCanViewPrivateContent[idx]
            if (email.toLowerCase().includes('@' + domain)) return false
        }
        return true
    }

    return (
        <Navbar 
            bg='white' 
            expand='lg' 
            className="shadow-sm mb-4" 
            style={{ 
                borderBottom: '1px solid #eaeaea',
                padding: '0.75rem 1rem'
            }}
        >
            <Container fluid>
                <Navbar.Brand 
                    href='https://pricing.ficc.ai/pricing' 
                    style={{ 
                        fontWeight: '700',
                        color: '#2c3e50',
                        fontSize: '1.5rem',
                        letterSpacing: '-0.01em'
                    }}
                >
                    ficc<span style={{ color: '#3182ce' }}>.ai</span>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls='basic-navbar-nav' />
                <Navbar.Collapse id='basic-navbar-nav'>
                    <Nav className='me-auto' style={{ marginLeft: '20px'}}>
                        {[
                            ['pricing', 'Pricing Engine'],
                            ['compliance', 'Compliance', cannotViewPrivateContent],
                            ['ficcyieldcurve', 'Real Time Yield Curve', cannotViewPrivateContent],
                            ['dailyschoonover', 'Real Time Muni Market Stats'],
                            ['contactTab', 'Contact']
                        ].map(([path, label, condition]) => 
                            condition && condition(userEmail) ? null : (
                                <Nav.Link 
                                    key={path}
                                    href={path} 
                                    style={{ 
                                        marginLeft: '10px', 
                                        marginRight: '10px',
                                        padding: '8px 16px',
                                        borderRadius: '6px',
                                        transition: 'all 0.2s ease',
                                        color: '#4a5568',
                                        fontWeight: '500'
                                    }}
                                    className="hover-effect"
                                    onMouseOver={(e) => e.currentTarget.style.backgroundColor = '#f7fafc'}
                                    onMouseOut={(e) => e.currentTarget.style.backgroundColor = 'transparent'}
                                >
                                    {label}
                                </Nav.Link>
                            )
                        )}
                    </Nav>
                    <div className="d-flex align-items-center">
                        <span className="text-muted small" style={{ 
                            marginRight: '15px', 
                            padding: '6px 12px',
                            border: '1px solid #e2e8f0',
                            borderRadius: '6px',
                            backgroundColor: '#f8fafc',
                            fontSize: '0.85rem',
                            color: '#64748b',
                            fontWeight: '500'
                        }}>
                            Powered by S&P Data
                        </span>
                        {message && (
                            <Navbar.Text 
                                className='me-3' 
                                style={{
                                    marginRight: '15px',
                                    color: '#4a5568',
                                    fontSize: '0.9rem',
                                    backgroundColor: '#EDF2F7',
                                    padding: '6px 12px',
                                    borderRadius: '6px'
                                }}
                            >
                                {message}
                            </Navbar.Text>
                        )}
                        <div style={{
                            marginLeft: '10px',
                            padding: '6px 12px',
                            backgroundColor: '#f8fafc',
                            borderRadius: '6px',
                            border: '1px solid #e2e8f0',
                            fontSize: '0.9rem',
                            color: '#4a5568',
                            display: 'flex',
                            alignItems: 'center'
                        }}>
                            <span style={{ marginRight: '5px' }}>Signed in as:</span>
                            <a 
                                href='login'
                                style={{
                                    color: '#3182ce',
                                    textDecoration: 'none',
                                    fontWeight: '500'
                                }}
                                className="hover-link"
                            >
                                {userEmail}
                            </a>
                        </div>
                    </div>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}

export default NavBarTop